
import { defineComponent, ref, nextTick, reactive } from 'vue';
import {
    applicableTypeClassList,
    getDealerLogListByCondition,
} from '@/API/localRegistration';
import moment from 'moment';

export default defineComponent({
    setup(props) {
        //分页组件
        const pagination = reactive({
            pageSize: 20,
            currentPage: 1,
            total: 0,
        });

        const columns = [
            {
                title: 'Dealer/SWT Code',
                dataIndex: 'dealerCode',
                key: 'dealerCode',
            },
            {
                title: 'Dealer Name',
                dataIndex: 'dealerName',
                key: 'dealerName',
            },
            {
                title: 'Province',
                dataIndex: 'province',
                key: 'province',
            },
            {
                title: 'Part I Area',
                dataIndex: 'city',
                key: 'city',
            },
            {
                title: 'Province II',
                dataIndex: 'provinces',
                key: 'provinces',
                slots: { customRender: 'provinces' },
            },
            {
                title: 'Part II Area',
                dataIndex: 'city',
                key: 'city',
                slots: { customRender: 'PartIIArea' },
            },
            {
                title: 'Part II Area No.',
                dataIndex: 'applicableVO.cityCount',
                key: 'applicableVO.cityCount',
            },
            {
                title: 'Region',
                dataIndex: 'region',
                key: 'region',
            },
            {
                title: 'Subregion',
                dataIndex: 'subRegion',
                key: 'subRegion',
            },
            {
                title: 'Dealer Group',
                dataIndex: 'dealerGroup',
                key: 'dealerGroup',
            },
            {
                title: 'Special Area',
                dataIndex: 'specialArea',
                key: 'specialArea',
                slots: { customRender: 'specialArea' },
            },
            {
                title: 'Applicable Type Class',
                dataIndex: 'applicableTypeClass',
                key: 'applicableTypeClass',
            },
            {
                title: 'Effective Time',
                dataIndex: 'applicableVO.effectiveTime',
                key: 'applicableVO.effectiveTime',
            },
            {
                title: 'Change Reason',
                dataIndex: 'changeReason',
                key: 'changeReason',
            },
        ];

        const data = ref<any[]>([]);

        const startTime = ref<string>('');
        const endTime = ref<string>(moment(new Date()).format('YYYY-MM-DD'));
        const typeClass = ref<string[]>([]);
        const typeClassList = ref<string[]>([]);

        const handleReset = () => {
            startTime.value = '';
            endTime.value = moment(new Date()).format('YYYY-MM-DD');
            typeClass.value = [];
        };

        const totalCount = ref<number>(0);
        let totalList: any[] = [];
        const addCount = ref<number>(0);
        const addList: any[] = [];
        const deleteCount = ref<number>(0);
        const deleteList: any[] = [];
        const changeCount = ref<number>(0);
        const changeList: any[] = [];

        // 刷新表格高度
        const tableHeight = ref('0');
        const getTableScroll = () => {
            nextTick(() => {
                //  默认底部分页50 + 边距10
                const extraHeight = 150;
                const tHeader = document.getElementsByClassName(
                    'content-container'
                )[0];
                //表格内容距离顶部的距离
                let tHeaderBottom = 0;
                if (tHeader) {
                    tHeaderBottom = tHeader.getBoundingClientRect().top;
                }
                //窗体高度-表格内容顶部的高度-表格内容底部的高度
                const height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
                tableHeight.value = height;
            });
        };

        const handleSearch = () => {
            const list =
                typeClass.value?.length > 0
                    ? typeClass.value
                    : typeClassList.value;
            const params = {
                num: pagination.currentPage,
                size: pagination.pageSize,
                applicableTypeClass: list,
                startTime:
                    moment(startTime.value).format('YYYY-MM-DD') ===
                    'Invalid date'
                        ? '1990-01-01'
                        : moment(startTime.value).format('YYYY-MM-DD'),
                endTime:
                    moment(startTime.value).format('YYYY-MM-DD') ===
                    'Invalid date'
                        ? moment(new Date()).format('YYYY-MM-DD')
                        : moment(startTime.value).format('YYYY-MM-DD'),
            };
            getDealerLogListByCondition(params).then((res: any) => {
                pagination.total = res.data.total;
                const allData = res.data.data;
                totalList = allData || [];
                totalCount.value = res.data.total;
                addCount.value = res.data.addNo;
                deleteCount.value = res.data.deleteNo;
                changeCount.value = res.data.editNo;

                data.value = allData;
                getTableScroll();
            });
        };

        const pageChange = (page: number) => {
            pagination.currentPage = page > 0 ? page : 0;
            handleSearch();
        };
        const sizeChange = (page: number, pageSize: number) => {
            pagination.currentPage = page > 0 ? page : 0;
            pagination.pageSize = pageSize;
            handleSearch();
        };

        const handleClickCount = (type: string) => {
            switch (type) {
                case 'total':
                    data.value = totalList;
                    break;
                case 'add':
                    data.value = addList;
                    break;
                case 'delete':
                    data.value = deleteList;
                    break;
                case 'change':
                    data.value = changeList;
                    break;
            }
        };

        const init = () => {
            applicableTypeClassList().then((res: any) => {
                typeClassList.value = res;
            });
        };
        init();

        return {
            startTime,
            endTime,
            typeClass,
            typeClassList,
            columns,
            data,
            totalCount,
            addCount,
            deleteCount,
            changeCount,
            handleClickCount,
            handleReset,
            handleSearch,
            tableHeight,
            pagination,
            pageChange,
            sizeChange,
        };
    },
});
